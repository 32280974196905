export const formatNumberStringAsTimeString = (timeString) => {
  return [timeString.slice(0, 2), timeString.slice(2)].join(":");
};

export const convertTimeStringToInteger = (timeString) => {
  return parseInt(timeString.replace(":", ""), 10);
};

export const convertToIsoDate = (dateString) => {
  return dateString.split("/").reverse().join("-");
};
