<template>
  <ol
    class="Timezones"
    role="list"
    :style="{
      '--amount': timezoneGroups.length,
      '--size': timezoneGroups.length / Math.pow(1.5, timezoneGroups.length),
    }"
  >
    <li
      v-for="(timezoneGroup, i) in timezoneGroups"
      :key="i"
      class="Timezone"
      :class="{ 'is-dateline': timezoneGroup.dateline }"
    >
      <div class="Timezone-wrapper">
        <h2 class="Timezone-names">
          <span
            v-for="(timezone, k) in timezoneGroup.timezones"
            :key="k"
            class="Timezone-name"
          >
            {{ timezone.formatted }}
          </span>
        </h2>
        <time>
          <span class="Timezone-date"
            >{{ timezoneGroup.weekday }}, {{ timezoneGroup.date }}</span
          >
          <span class="Timezone-time">{{ timezoneGroup.time }}</span>
        </time>
      </div>
      <div class="Timezone-users">
        <TimezoneUsers
          :users="timezoneGroup.users"
          :time="timezoneGroup.time"
          :weekday="timezoneGroup.weekday"
          :date="timezoneGroup.date"
          :offset-to-active-user="timezoneGroup.offsetToActiveUser"
        />
      </div>
    </li>
  </ol>
</template>

<script>
import { ref } from "vue";
import TimezoneUsers from "./Users.vue";

import { convertToIsoDate } from "../utils";

export default {
  name: "TimezoneList",
  components: {
    TimezoneUsers,
  },

  props: {
    users: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const currentTime = ref(new Date());

    return {
      currentTime,
    };
  },

  computed: {
    timezoneGroups() {
      const allTimezones = [
        ...new Set(this.users.map((user) => user.timezone)),
      ];
      const allTimezonesWithTime = allTimezones.map((timezone) => ({
        timezone,
        dateTime: this.getDateTimeForTimeZone(timezone),
      }));
      const allDateTimes = [
        ...new Set(allTimezonesWithTime.map((timezone) => timezone.dateTime)),
      ];
      const timezoneGroups = allDateTimes
        .map((dateTime) => {
          const timezones = allTimezonesWithTime
            .filter((timeZone) => timeZone.dateTime === dateTime)
            .map((timezone) => timezone.timezone);
          const date = this.getDateForTimeZone(timezones[0]);
          const time = this.getTimeForTimeZone(timezones[0]);
          const timestamp = new Date(
            `${convertToIsoDate(date)}T${time}`
          ).getTime();

          return {
            dateTime,
            timezones,
            timestamp,
          };
        })
        .sort((a, b) => {
          if (a.timestamp > b.timestamp) return 1;
          if (b.timestamp > a.timestamp) return -1;
          return 0;
        })
        .map((dateTime) => {
          const timezone = dateTime.timezones[0];
          const date = this.getDateForTimeZone(timezone);
          const time = this.getTimeForTimeZone(timezone);
          const { timestamp } = dateTime;
          const timestampCurrentUser = new Date(
            new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
          ).getTime();

          return {
            timezones: dateTime.timezones.map((tz) => ({
              timezone: tz,
              formatted: tz ? tz.replace("/", " · ").replaceAll("_", " ") : "",
            })),
            weekday: this.getWeekdayForTimeZone(timezone),
            date,
            time,
            users: this.users.filter((user) =>
              dateTime.timezones.includes(user.timezone)
            ),
            offsetToActiveUser: timestamp - timestampCurrentUser,
          };
        });

      const allWeekdays = [
        ...new Set(timezoneGroups.map((group) => group.weekday)),
      ];

      if (allWeekdays.length === 2) {
        timezoneGroups.find(
          (group) => group.weekday === allWeekdays[1]
        ).dateline = true;
      }

      return timezoneGroups;
    },
  },

  beforeMount() {
    this.setCurrentTime();
  },

  methods: {
    setCurrentTime() {
      this.currentTime = new Date();

      setTimeout(this.setCurrentTime, 1000);
    },

    getWeekdayForTimeZone(timezone) {
      return new Intl.DateTimeFormat(["en-GB"], {
        timeZone: timezone,
        weekday: "short",
      })
        .format(this.currentTime)
        .toLowerCase();
    },

    getDateTimeForTimeZone(timezone) {
      return new Intl.DateTimeFormat([], {
        timeZone: timezone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(this.currentTime);
    },

    getDateForTimeZone(timeZone) {
      return new Intl.DateTimeFormat(["en-GB"], {
        timeZone,
      }).format(this.currentTime);
    },

    getTimeForTimeZone(timezone) {
      return new Intl.DateTimeFormat(["en-GB"], {
        timeZone: timezone,
        hour: "numeric",
        minute: "numeric",
      }).format(this.currentTime);
    },
  },
};
</script>

<style scoped>
.Timezones {
  list-style: none;
}

.Timezone:not(:first-child) {
  --border-width: 0.1rem;

  border: 0 dashed hsla(0, 0%, 100%, 0.1);
}

.Timezone.is-dateline {
  border-color: var(--color-blue);
}

.Timezone-names {
  --font-size: 1.6;
  --line-height: 1.5;
}

.Timezone-name {
  color: var(--color-green);
  display: block;
  font-size: calc(var(--font-size) * 1rem);
  font-weight: 600;
}

.Timezone-date {
  color: var(--color-grey);
  display: block;
  font-family: SuisseIntlMono;
  font-size: 1.4rem;
}

.Timezone-date::first-letter {
  text-transform: uppercase;
}

.Timezone-time {
  display: block;
  font-size: clamp(3.2rem, calc(var(--size) * 10vw), 5.6rem);
  line-height: 1;
}

@media (max-width: 59.96875em) {
  .Timezone-wrapper {
    flex-direction: column;
  }
}

@media (min-width: 60em) and (max-width: 85.96875em) {
  .Timezone-wrapper > * {
    flex: 1;
  }
}

@media (max-width: 85.96875em) {
  .Timezone {
    display: flex;
    gap: 2em;
    padding: 2em;
  }

  .Timezone > * {
    flex: 1;
  }

  .Timezone:not(:first-child) {
    border-block-start-width: var(--border-width);
  }

  .Timezone-wrapper {
    display: flex;
    gap: 1em;
  }
}

@media (min-width: 86em) {
  .Timezones {
    display: grid;
    grid-template-columns: repeat(var(--amount), 1fr);
    grid-template-rows: auto auto 1fr;
  }

  .Timezone {
    display: grid;
    gap: 1em 2em;
    grid-row: 1 / 3;
    padding: 2em max(2rem, 10%);
  }

  .Timezone:not(:first-child) {
    border-inline-start-width: var(--border-width);
  }

  .Timezone-wrapper {
    background: var(--color-dark-blue);
    display: flex;
    flex-direction: column;
    inset-block-start: 0;
    padding-block: 1em;
    position: sticky;
    z-index: 1;
  }

  .Timezone-names {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }

  .Timezone-date {
    margin-block-start: 1rem;
  }

  .Timezone-users {
    padding-block-start: 1rem;
  }
}

@supports (grid-template-columns: subgrid) {
  @media (max-width: 85.96875em) {
    .Timezone {
      grid-template-columns: subgrid;
    }
  }

  @media (min-width: 86em) {
    .Timezone {
      grid-template-rows: subgrid;
    }
  }
}

@supports not (grid-template-columns: subgrid) {
  @media (min-width: 86em) {
    .Timezone {
      grid-template-rows: auto 1fr;
    }

    .Timezone-names {
      /* ugly temporary fix until Chrome and Safari implement display:subgrid */
      min-height: calc(2rem * calc(var(--font-size) * var(--line-height)));
    }
  }
}
</style>
