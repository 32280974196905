<template>
  <div class="Spinner"></div>
</template>

<script>
export default {
  name: "TimezoneSpinner",
};
</script>

<style scoped>
.Spinner {
  --size: 2em;
  animation: spinner 1s linear infinite;
  border: 0.5em solid transparent;

  border-radius: 50%;
  border-top-color: var(--color-orange);
  height: var(--size);
  left: 50%;
  position: absolute;
  top: 50%;
  width: var(--size);
}

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
