<template>
  <ol
    class="Users"
    role="list"
    :data-weekend="['sat', 'sun'].includes(weekday)"
  >
    <li
      v-for="(user, k) in usersList"
      :key="k"
      class="User"
      :data-available="user.available.isAvailable"
      :data-guessed="user.available.guessed"
    >
      <span class="User-name">
        {{ user.name }}
      </span>
      <span class="User-availability"
        ><span class="u-hiddenVisually"
          >available: {{ user.available.isAvailable }}</span
        ></span
      >
      <span class="User-reason">
        <span class="u-hiddenVisually">Working hours:</span>
        <template v-if="user.available.workingHours">
          <template v-if="user.available.userIsInDifferentTimezone">
            <b>Your time:</b><br />
            <span
              class="User-workingHours"
              v-html="user.available.workingHoursForActiveUser"
            ></span
            ><br />
            <b>Local time:</b><br />
            <span
              class="User-workingHours"
              v-html="user.available.workingHours"
            ></span>
          </template>
          <span
            v-else
            v-html="user.available.workingHours"
            class="User-workingHours"
          ></span>
        </template>
        <span v-else>{{ user.available.reason || "Unknown" }}</span>
      </span>
    </li>
  </ol>
</template>

<script>
import {
  formatNumberStringAsTimeString,
  convertTimeStringToInteger,
  convertToIsoDate,
} from "../utils";

const typeOfDayMapping = {
  publicHoliday: "Public holiday",
  weekend: "Weekend",
  workingDay: "Working Day",
  nonWorkingDay: "Non-working Day",
  ooo: "Out of Office",
};

export default {
  name: "TimezoneUsers",

  props: {
    users: {
      type: Array,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    weekday: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    offsetToActiveUser: {
      type: Number,
      required: true,
    },
  },

  computed: {
    usersList() {
      return this.users
        .map((user) => {
          const isAvailableAccordingToWorkingHours = this.isAvailable(
            user,
            this.time,
            this.weekday
          );

          let isAvailableAccordingToControlling;

          if (
            typeof user.isWorking === "undefined" ||
            !user.isWorking[this.standardizedDate] ||
            !user.isWorking[this.standardizedDate].type
          ) {
            isAvailableAccordingToControlling = false;
          } else {
            isAvailableAccordingToControlling =
              user.isWorking[this.standardizedDate].type === "workingDay";
          }

          let isAvailable;
          let guessed;
          let reason;
          let workingHours;
          let workingHoursForActiveUser;

          if (["sat", "sun"].includes(this.weekday)) {
            isAvailable = false;
            guessed = false;
            reason = "Weekend";
          } else if (!isAvailableAccordingToControlling) {
            isAvailable = false;
            guessed = false;

            if (user.isWorking[this.standardizedDate]?.type) {
              reason =
                typeOfDayMapping[user.isWorking[this.standardizedDate].type];
            } else {
              reason = "Unknown";
            }
          } else if (isAvailableAccordingToWorkingHours.guessed) {
            isAvailable = isAvailableAccordingToWorkingHours.state;
            guessed = true;
            reason = "No working hours defined";
          } else {
            isAvailable = isAvailableAccordingToWorkingHours.state;
            guessed = false;

            if (isAvailableAccordingToWorkingHours.workingHours.length > 0) {
              workingHours = isAvailableAccordingToWorkingHours.workingHours
                .map((time) => `${time.start} – ${time.end}`)
                .join("<br>");

              workingHoursForActiveUser =
                isAvailableAccordingToWorkingHours.workingHours
                  .map((time) => {
                    const offset = this.offsetToActiveUser / 60 / 60 / 10;
                    const start = convertTimeStringToInteger(time.start);
                    const end = convertTimeStringToInteger(time.end);

                    return `${formatNumberStringAsTimeString(
                      String(
                        this.convertTimeToTimeOfActiveUser(start, offset)
                      ).padStart(4, "0")
                    )} – ${formatNumberStringAsTimeString(
                      String(
                        this.convertTimeToTimeOfActiveUser(end, offset)
                      ).padStart(4, "0")
                    )}`;
                  })
                  .join("<br>");
            } else {
              reason = "Not available";
            }
          }

          return {
            ...user,
            available: {
              isAvailable,
              guessed,
              reason,
              workingHours,
              workingHoursForActiveUser,
              userIsInDifferentTimezone:
                workingHours !== workingHoursForActiveUser,
            },
          };
        })
        .sort((a, b) => {
          if (a.available.isAvailable && !b.available.isAvailable) return -1;
          if (!a.available.isAvailable && b.available.isAvailable) return 1;

          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;

          return 0;
        });
    },

    standardizedDate() {
      return convertToIsoDate(this.date);
    },
  },

  methods: {
    convertTimeToTimeOfActiveUser(time, timezoneOffset) {
      const absolute =
        time - timezoneOffset < 0
          ? 2400 + time - timezoneOffset
          : time - timezoneOffset;

      return absolute > 2400 ? absolute - 2400 : absolute;
    },

    isAvailable(user, time, weekday) {
      const rawTime = convertTimeStringToInteger(time);

      if (["sat", "sun"].includes(weekday))
        return { state: false, guessed: false };
      if (!user.workingHours) {
        return {
          state: rawTime > 900 && rawTime < 1800,
          guessed: true,
        };
      }

      let workingHours;

      if (Array.isArray(user.workingHours)) {
        workingHours = user.workingHours;
      } else {
        workingHours = user.workingHours[weekday];
      }

      if (!workingHours) return { state: false, guessed: false };

      const converted = workingHours.map((entry) => ({
        start: convertTimeStringToInteger(entry.start),
        end: convertTimeStringToInteger(entry.end),
      }));
      const timezoneTime = convertTimeStringToInteger(time);
      const workingHoursMatches = [];

      converted.forEach((entry) => {
        workingHoursMatches.push(
          entry.start <= timezoneTime && timezoneTime <= entry.end
        );
      });

      return {
        state: workingHoursMatches.some((entry) => entry === true),
        guessed: false,
        workingHours,
      };
    },
  },
};
</script>

<style scoped>
@import "@factorial/fsk/css/utils.css";

.Users {
  list-style: none;
  order: -1;
}

.Users[data-weekend="true"] {
  color: var(--color-grey);
}

.Users > * + * {
  margin-block-start: 1em;
}

.User {
  display: flex;
  font-size: 1.4rem;
  gap: 0.5em;
  position: relative;
}

.User[data-available="false"] {
  color: var(--color-grey);
}

.User-reason,
.User-reason::before {
  display: block;
  opacity: 0;
  position: absolute;
  transform: translateY(0.25em);
  transition: transform 0.1s ease;
  z-index: -1;
}

.User-reason {
  background: var(--color-orange);
  border-radius: 0.2em;
  bottom: 100%;
  color: var(--color-white);
  left: -0.25em;
  padding: 0.25em 0.5em;
  pointer-events: none;
}

.User-reason::before {
  border: 0.25em solid transparent;
  border-top-color: var(--color-orange);
  content: "";
  display: block;
  height: 0;
  left: 0.25em;
  position: absolute;
  top: 100%;
  width: 0;
}

.User:hover .User-reason,
.User:hover .User-reason::before {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.1s ease;
  z-index: 1;
}

.User-workingHours {
  font-family: SuisseIntlMono;
  white-space: nowrap;
}

.User-availability {
  --size: 0.5em;

  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  flex: 0 0 var(--size);
  height: var(--size);
  order: -1;
  transform: translateY(0.5em);
}

[data-available="false"] .User-availability {
  color: var(--color-orange);
}

[data-available="true"] .User-availability {
  color: var(--color-green);
}

[data-guessed="true"] .User-availability {
  border: 0.1rem solid currentColor;
}

[data-guessed="false"] .User-availability {
  background: currentColor;
}
</style>
