<template>
  <div>
    <Spinner v-if="isLoading" />
    <TimezoneList v-else-if="loggedIn" :users="users" />
    <button
      v-else
      class="FskButton LoginButton"
      @click="client.requestAccessToken"
    >
      Sign In
    </button>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import Spinner from "./components/Spinner.vue";
import TimezoneList from "./components/List.vue";

const scope = "https://www.googleapis.com/auth/calendar.readonly";

let ws;

export default {
  components: {
    Spinner,
    TimezoneList,
  },

  setup() {
    const isLoading = ref(true);
    const loggedIn = ref(false);

    return {
      isLoading,
      loggedIn,
    };
  },

  data() {
    return {
      users: [],
    };
  },

  mounted() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => window.gapi.load("client:auth2", this.initClient);
    document.body.appendChild(script);
  },

  methods: {
    initClient() {
      this.client = window.google.accounts.oauth2.initTokenClient({
        client_id: import.meta.env.VITE_CLIENT_ID,
        scope,
        callback: (response) => {
          this.isLoading = true;

          if (response && response.access_token) {
            localStorage.setItem("logged_in", true);
            localStorage.setItem(
              "expires_in",
              Date.now() + response.expires_in * 1000
            );

            if (
              window.google.accounts.oauth2.hasGrantedAnyScope(response, scope)
            ) {
              this.initWS();
            }
          }
        },
      });

      if (
        localStorage.getItem("logged_in") &&
        localStorage.getItem("expires_in") &&
        Date.now() < localStorage.getItem("expires_in")
      ) {
        this.isLoading = true;
        this.initWS();
      } else {
        localStorage.removeItem("logged_in");
        localStorage.removeItem("expires_in");
        this.isLoading = false;
      }
    },

    initWS() {
      window.gapi.client.setApiKey(import.meta.env.VITE_API_KEY);
      window.gapi.client.load("calendar", "v3", () => {
        ws = new WebSocket(import.meta.env.VITE_WEBSOCKET_URL);
        ws.onmessage = async (msg) => {
          this.users = JSON.parse(msg.data);

          await nextTick();

          this.loggedIn = true;
          this.isLoading = false;
        };
      });
    },
  },
};
</script>

<style>
@import "@factorial/fsk/templates/form/button/button.css";

.Spinner ~ * {
  display: none !important;
}

.LoginButton {
  --FskButton-border-radius: 0.2em;
  --FskButton-color: var(--color-dark-blue);
  --FskButton-color--hover: var(--color-dark-blue);
  --FskButton-color--active: var(--color-dark-blue);
  --FskButton-color--focus: var(--color-dark-blue);
  --FskButton-bg-color: var(--color-green);
  --FskButton-bg-color--hover: var(--color-green);
  --FskButton-bg-color--active: var(--color-green);
  --FskButton-bg-color--focus: var(--color-green);

  font-family: SuisseSign;
  font-weight: 600;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
